<template>
  <el-collapse v-model="filter" @change="handleChange">
    <el-collapse-item title="Фильтр" name="p1">
      <el-row class="m-0 filter">
        <el-col :span="12" class="filter--container ml-2">
          <span class="mr-2">Наименование:</span>
          <remote-search-input
            v-model="filterModel.nameFilter"
            id="estimation-filter--name_input"
            searchUrl="/api/estimations/SearchByName"
          />
        </el-col>
      </el-row>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import RemoteSearchInput from '@/components/Ui/RemoteSearchInput';
import UserSettingsService from '@/service/userSettingsService';
import { mapGetters } from 'vuex';

export default {
  name: 'EstimationsFilter',
  props: {
    filterModel: { type: Object, default: () => {} },
  },
  components: { RemoteSearchInput },
  data() {
    return {
      filter: ['']
    };
  },
  created() {
    if (UserSettingsService.has('estimationsFilter_filter_open', this.user.id)) {
      this.filter = ['p1'];
    }
  },
  computed: {
    ...mapGetters('identity', ['user'])
  },
  methods: {
    handleChange(val) {
      if (val.find((el) => el === 'p1')) {
        UserSettingsService.set('estimationsFilter_filter_open', true, this.user.id);
      } else {
        UserSettingsService.delete('estimationsFilter_filter_open', this.user.id);
      }
      const objectLength = Object.keys(val);
      if (objectLength.length > 0) {
        // this.filterModel.isActive = true;
        this.$emit('active-change', true);
       } else {
         // this.filterModel.isActive = false;
        this.$emit('active-change', false);
      }
    }
  }
};
</script>

<style scoped>

</style>
